import { Suspense, lazy } from 'react';

import SuspenseLoader from '../components/SuspenseLoader';
import LectureContainer from '../content/dashboards/Course/single/Lectures/LectureContainer';

// import LectureContainerLab  from '../content/dashboards/Lab/single/Lectures/LectureContainer';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Learning = Loader(lazy(() => import('../content/dashboards/Learning')));
const Challenge = Loader(lazy(() => import('../content/dashboards/Challenge')));
const Course = Loader(lazy(() => import('../content/dashboards/Course')));
// const Lab = Loader(lazy(() => import('../content/dashboards/Lab')));
const MyLearning = Loader(lazy(() => import('../content/dashboards/MyLearning')));
//const LectureContainer = Loader(lazy(() => import('../content/dashboards/Course/single/Lectures/LectureContainer')));

const SingleCourse = Loader(
  lazy(() => import('../content/dashboards/Course/single'))
);


// const SingleLab = Loader(
//   lazy(() => import('../content/dashboards/Lab/single'))
// );

const DetailChallenge = Loader(
  lazy(() => import('../content/dashboards/Challenge/single'))
);

const UserTimeline = Loader(
  lazy(() => import('../content/management/Users/single/Timeline'))
);


const dashboardsRoutes = [
  {
     path: '',
     element: <Learning />
  },
  {
    path: 'learning',
    element: <Learning />
  },
  {
    path: 'myLearning',
    element: <MyLearning />
  },
  {
    path: 'lecture/:courseId/:moduleId/:contentId',
    element: <LectureContainer key={Math.random()}/>
    
  },
  {
    path: 'course',
    children: [
      {
        path: '',
        element: <Course />
      },
      {
        path: 'detail',
        children: [
          {
            path: ':courseId',
            element: <SingleCourse />
          }
        ]
      },
      {
        path: 'division',
        children: [
          {
            path: ':divisionID',
            element: <Course />
          }
        ]
      }
    ]
  },
  // {
  //   path: 'lab',
  //   children: [
  //     {
  //       path: '',
  //       element: <Lab />
  //     },
  //     {
  //       path: 'detail',
  //       children: [
  //         {
  //           path: ':labId',
  //           element: <SingleLab />
  //         }
  //       ]
  //     }
  //   ]

  // },
  {
    path: 'challenge',
    children: [
      {
        path: '',
        element: <Challenge />
      },
      {
        path: 'detail',
        children: [
          {
            path: ':challengeId',
            element: <DetailChallenge />
          }
        ]
      }
    ]

  },
  {
    path: 'timeline',
    children: [
      {
        path: '',
        children: [
          {
            path: ':userId',
            element: <UserTimeline />
          }
        ]
      }
    ]
  },
  

 
];

export default dashboardsRoutes;
