import axiosClient from "../apiClient";


export async function  getInstitutions(term){
   
    return axiosClient.get('/api/institution/');
   
}



// export async function  getLabByCat(category_id){
   
//     return axiosClient.get(`/api/lab/by-cat/${category_id}/`);
   
// }

// export async function  getLabSearch(query){
   
//     return axiosClient.get(`/api/lab/search?query=${query}`);
   
// }

// export async function  getLabDetail(uuid){
   
//     return axiosClient.get(`/api/lab/${uuid}/`);
   
// }

// export async function  isEnrolled(uuid){
   
//     return axiosClient.get(`/api/lab/${uuid}/isEnrolled/`);
   
// }

// export async function  Enroll(uuid){
   
//     return axiosClient.post(`/api/lab/${uuid}/Enroll/`);
   
// }

// export async function  getStudentProgress(uuid){
   
//     return axiosClient.get(`/api/lab/progress/${uuid}/last_content/`);
   
// }

// export async function  labReview(uuid,data){
   
//     return axiosClient.post(`/api/lab/${uuid}/review/`,data);
   
// }

// export async function  getContent(uuid,moduleId,contentId){
   
//     return axiosClient.get(`/api/lab/${uuid}/content/${moduleId}/${contentId}/`);
   
// }

// export async function getGetNext(uuid,moduleId,contentId){
   
//     return axiosClient.post(`/api/lab/${uuid}/next/${moduleId}/${contentId}/`);
   
// }


// export async function setCompletedContent(uuid,moduleId,contentId){
   
//     return axiosClient.post(`/api/lab/${uuid}/complete/${moduleId}/${contentId}/`);
   
// }

// export async function  getPreviousContent(uuid,moduleId,contentId){
   
//     return axiosClient.post(`/api/lab/${uuid}/previous/${moduleId}/${contentId}/`);
   
// }

// export async function  getQuiz(moduleId){
   
//     return axiosClient.get(`/api/lab/quiz/${moduleId}/`);
   
// }

// export async function  getQuestionHint(moduleId,questionId){
   
//     return axiosClient.get(`/api/lab/quiz/${moduleId}/hint/${questionId}/`);
   
// }


// export async function  sendSimpleAsnswer(quizId,questionId,data){
//     // console.log("DATA ",data)
//     return axiosClient.post(`/api/lab/quiz/${quizId}/validate/${questionId}/`,data);
   
// }

// export async function  getDownload(uuid,moduleId,contentId){
   
//     return axiosClient.get(`/api/lab/courses/${uuid}/download/${moduleId}/${contentId}/`);
   
// }

// export async function  getCertifiedRequest(uuid){
   
//     return axiosClient.get(`/api/lab/courses/${uuid}/cert_request/`);
   
// }

