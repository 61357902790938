import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import LectureVideo from "./LectureVideo";
import LectureFile from "./LectureFile";
import LectureText from "./LectureText";
import LectureQuiz from "./quiz/LectureQuiz";
import { Snackbar } from "@mui/material";

import LoadingSpinner from "../../../../../components/LoadSpinner";

import { SidebarCourseContext } from "../../../../../contexts/SidebarCourseContext";

import { makeStyles } from "@mui/styles";
import CourseContext from "../../../../../contexts/CourseContext";
import LectureFinished from "./LectureFinished";
import { useTranslation } from "react-i18next";

import MuiAlert from "@mui/material/Alert";

const drawerWidth = 290;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MainContent = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  position: "relative",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Lecture = ({ courseId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { sidebarToggle, closeSidebar, toggleSidebar } =
    useContext(SidebarCourseContext);

  const [completed, setCompleted] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    courseContent,
    courseCompleted,
    getCourseContent,
    hasShownFinished,
    reasonNotCompleted,
    setReasonNotCompleted,
    contentFinished,
    handleLoad,
    handleNext,
    handlePrevious,
    handleCompleted,
  } = useContext(CourseContext);

  const [openAlertComplete, setOpenAlertComplete] = useState(false);
  const [hasShownAlertComplete, setHasShownAlertComplete] = useState(false);

  const [snackPosition, setSnackPosition] = useState({
      vertical: "bottom",
      horizontal: "center",
    });
  
    const { vertical, horizontal } = snackPosition;
  
  const handleNextContent = () => {
    setCompleted(false);
    handleNext();
  };

  const handlePreviousContent = () => {
    setCompleted(false);
    handlePrevious();
  };

  const handleCompletedContent = () => {
    setCompleted(false);
    handleCompleted();
  };

  useEffect(() => {
    if (courseCompleted) {
      setCompleted(true);
    }
  }, [courseCompleted]);

  useEffect(() => {
    handleLoad();
  }, []);

  useEffect(() => {
    if (reasonNotCompleted === "not_completed") {
      setOpenAlertComplete(true);
      setReasonNotCompleted("");
    }
  }, [reasonNotCompleted]);

  const handleCloseAlert = (event, reason) => {
    
    setOpenAlertComplete(false);
  };

  return courseContent ? (
    <MainContent
      open={sidebarToggle}
      sx={{
        maxWidth: `calc(100% - 40px)`,
      }}
    >
      <DrawerHeader />
      <Box
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          {isSmallScreen ? (
            <Tooltip title={courseContent.lecture.title}>
              <Typography align="center" variant={isSmallScreen ? "h6" : "h3"}>
                {courseContent.lecture.title}
              </Typography>
            </Tooltip>
          ) : (
            <Typography align="center" variant={isSmallScreen ? "h6" : "h3"}>
              {" "}
              {courseContent.lecture.title}
            </Typography>
          )}
        </Grid>
      </Box>
      <Snackbar
              open={openAlertComplete}
              autoHideDuration={5000}
              
              key={"wrong"}
              anchorOrigin={{ vertical, horizontal }}
              sx={{ zIndex: 10000 }}
              onClose={handleCloseAlert}
            >
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                onClose={handleCloseAlert}
              >
                {t("course_imcomplete")} ❌!
              </Alert>
            </Snackbar>
      
      <Grid>
        <Grid item>
          {console.log("Completed", completed, hasShownFinished)}
          {completed && hasShownFinished === false && (
            <LectureFinished courseId={courseId} />
          )}
          {completed === false && courseContent.lecture.type === "video" ? (
            <LectureVideo
              {...courseContent}
              handleNextContent={handleNextContent}
            />
          ) : null}
          {completed === false && courseContent.lecture.type === "file" ? (
            <LectureFile courseId={courseId} {...courseContent} />
          ) : null}
          {completed === false && courseContent.lecture.type === "text" ? (
            <LectureText {...courseContent} />
          ) : null}
          {completed === false && courseContent.lecture.type === "quiz" ? (
            <LectureQuiz {...courseContent} />
          ) : null}
        </Grid>
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Tooltip title={t("Anterior")}>
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "0.8rem",
                        fontBold: "bold",
                      }}
                      onClick={handlePreviousContent}
                      startIcon={<ArrowLeftIcon />}
                    >
                      {t("Anterior")}
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  {!courseContent.is_last_content && (
                    <Tooltip variant="contained" title={t("Continuar")}>
                      <Button
                        variant="outlined"
                        sx={{
                          fontSize: "0.8rem",
                          fontBold: "bold",
                        }}
                        onClick={handleNextContent}
                        endIcon={<ArrowRightIcon />}
                      >
                        {t("Próximo")}
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Tooltip variant="contained" title={t("Completar")}>
                <Button
                  variant="outlined"
                  color="success"
                  disabled={contentFinished}
                  sx={{
                    fontSize: "0.8rem",
                    fontBold: "bold",
                  }}
                  onClick={handleCompletedContent}
                >
                  {t("Completar")}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </MainContent>
  ) : (
    <LoadingSpinner />
  );
};

export default Lecture;
